<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="order-designs">
            <b-alert :show="!isDelivered" variant="warning">
              <p class="mb-0">
                <i class="mdi mdi-block-helper mr-2"></i> <span>This order is not delivered.</span>
              </p>
            </b-alert>
            <div v-if="order.stage == 'finished' && winner" class="mb-3">
              <h5 class="border-bottom pb-1 mb-3 text-muted">Winner</h5>
              <div class="row g-3">
                <div class="col-6 col-sm-6 col-lg-3" >
                  <div>
                    <delivery-card :d-index="1"  :delivery="winner" 
                      :view-delivery="showDelivery" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="finalists.length" class="mb-3">
              <h5 class="border-bottom pb-1 mb-3 text-muted">Finalists</h5>
              <div class="row g-3">
                <div v-for="(fDelivery, fdIndex) in finalists"  :key="fdIndex" class="col-6 col-sm-6 col-lg-3" >
                  <div>
                    <delivery-card :d-index="fdIndex+1"  :delivery="fDelivery"
                      :view-delivery="showDelivery" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="progress.length" class="">
              <h5 class="border-bottom pb-1 mb-3 text-muted">In Progress</h5>
              <div class="row g-3">
                <div v-for="(pDelivery, pdIndex) in progress" :key="pdIndex" class="col-6 col-sm-6 col-lg-3" >
                  <div>
                    <delivery-card :d-index="pdIndex+1"  :delivery="pDelivery"
                    :view-delivery="showDelivery" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="eliminated.length" class="">
              <h5 class="border-bottom pb-1 mb-3 text-muted">Eliminated</h5>
              <div class="row g-3">
                <div v-for="(eDelivery, edIndex) in eliminated" :key="edIndex" class="col-6 col-sm-6 col-lg-3" >
                  <div>
                    <delivery-card :d-index="edIndex+1"  :delivery="eDelivery"
                      :view-delivery="showDelivery" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="blocked.length" class="">
              <h5 class="border-bottom pb-1 mb-3 text-muted">Blocked</h5>
              <div class="row g-3">
                <div v-for="(bDelivery, bdIndex) in blocked" :key="bdIndex" class="col-6 col-sm-6 col-lg-3" >
                  <div>
                    <delivery-card :d-index="bdIndex+1"  :delivery="bDelivery" 
                    :view-delivery="showDelivery" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal size="xl" title="Delivery show"
      body-bg-variant="light" body-class="p-2 p-lg-3" header-class="py-2"
      button-size="sm" scrollable hide-footer v-model="deliveryModalShow">
      <delivery-show v-if="deliveryModalShow" :deliveryId="shownDeliveryID" />
    </b-modal>
  </div>
</template>
<script>

import DeliveryCard from "./DeliveryCard.vue"
import DeliveryShow from "./DeliveryShow.vue"

export default {
  components: {
    DeliveryCard,
    DeliveryShow
  },
  data(){
    return {
        deliveryModalShow: false,
        shownDeliveryID: null
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    deliveries(){
      return this.order.deliveries;
    },
    finalists(){
      return this.deliveries.filter(d => d.status == 'FINALIST')
    },
    progress(){
      return this.deliveries.filter(d => d.status == "PROGRESS")
    },
    eliminated(){
      return this.deliveries.filter(d => d.status == "ELIMINATED")
    },
    blocked(){
      return this.deliveries.filter(d => d.status == "BLOCKED")
    },
    winner(){
      return this.deliveries.find(d => d.status == 'WINNER') 
    },
    isDelivered(){
      return this.order.deliveries ? this.order.deliveries.length : false;
    },
  },
  methods:{
    showDelivery(deliveryId){
      this.deliveryModalShow = !this.deliveryModalShow
      this.shownDeliveryID = +deliveryId
    }
  },
}
</script>
