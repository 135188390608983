

<template>
  <div>
    <h4 class="mb-0">Features</h4>
    <hr class="mt-2">
    <ul class="row list-unstyled">
      <li class="col-sm-6 mb-1 col-lg-4">
        <span>✔️</span> <span>{{calculateDeliveryTime(orderPackage.delivery_time)}} Delivery</span>
      </li>
      <li class="col-sm-6 mb-1 col-lg-4">
        <span>✔️</span> <span>{{orderPackage.revisions}} Revisions</span>
      </li>
      <template v-for="(attribute, index) in attributes">
        <li class="col-sm-6 mb-1 col-lg-4" v-if="attribute.type == 'check' && supports[attribute.id] == 'supported'"  :key="index">
          <span>✔️</span> <span>{{attribute.name}} </span>
        </li>
        <li class="col-sm-6 mb-1 col-lg-4" v-if="attribute.type == 'input' && supports[attribute.id]"  :key="index">
          <span>✔️</span>
          <span> {{supports[attribute.id]}} {{attribute.name}} </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
    name: "feature-list",
    props: {
        cart: {
            type: Object,
            required: true,
        },
    },
    computed:{
      attributes(){
        return this.cart.attributes;
      },
      orderPackage(){
        return this.cart.package;
      },
      supports(){
        return this.orderPackage.attributes || {};
      }
    }
}
</script>
