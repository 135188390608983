<template>
  <div class="card">
    <div class="card-body">
        <div class="order-details-body">
          <div class="row mb-4">
            <div class="col-12">
              <h4 class="mb-0">Item Purchased: <a :href="absoluteUrl(`/${product.slug}`)" class="cursor-pointer" target="_blank"> {{product.name}} </a> </h4>
              <h4 class="mb-0"><span class="text-muted">{{orderPackage.name}} package at ${{orderPackage.price | money_format}} only </span></h4>
            </div>
            <div class="col-6 col-md-3">
              <h5 class="">Order Number: <span class="text-muted">#{{order.number}}  </span></h5>
            </div>
            <div class="col-6 col-md-3">
              <h5 class="">Status: <span class="text-muted text-capitalize">{{order.status}}  </span></h5>
            </div>
            <div class="col-12">
               <div class="text-muted">
                  Buyer:
                  <router-link :to="`/users/${order.buyer_id}`" class="cursor-pointer">
                  <i class="mdi mdi-eye"></i> {{order.buyer.first_name }} {{order.buyer.last_name }}
                  </router-link>
              </div>
            </div>
            <div class="col-12">
              <p class="mb-0">Date: <span class="text-muted">{{order.created_at | date(true)}}  </span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div>
                <feature-list :cart="cart" />
              </div>
              <div v-if="cart.addons && cart.addons.length" class="mt-4">
                <addons />
              </div>
              <div class="mt-4">
                <requirement-list />
              </div>
            </div>         
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import FeatureList from "./FeatureList.vue"
import RequirementList from "./RequirementList.vue"
import Addons from "./Addons.vue"

export default {
  components: {
    RequirementList,
    FeatureList,
    Addons
  },
  data() {
    return {
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.cart.package
    },
    product(){
      return this.cart.product
    },
  },
}

</script>

<style type="text/css" scoped>

    sub{font-size: 12px;}

    @media(max-width: 580px){
      .order-details-header{
        margin-bottom: 1.5em;

      }
    }
</style>
